.body {
    position: relative;
    z-index: 1;
    width: 100%;
    text-size-adjust: none;
    background: $colorBackMain;

    &__topBar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        pointer-events: none;
    }

    &__content {
        width: 100%;
        height: 100%;
    }

    &__pages {
        width: 100%;
        height: 100%;
    }

    &__page {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;

        &._popup {
            z-index: 10000;
        }

        & .list__itemBox {
            height: auto;
        }
    }

    &__popup {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;

        &Inner {
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }
    }
}
