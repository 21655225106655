.index {
    // margin-top: -4780px;
    background: #e11f26;

    &__section {
        &._about {
            position: relative;
            z-index: 2;
        }
    }
}
