.field {
    width: 100%;
    height: 100%;

    &__support {
        margin-bottom: 6px;
        padding-left: 15px;
        font-size: 18px;
        line-height: 1.5;

        @include mob {
            margin-bottom: 5px;
            padding-left: 16px;
            font-size: 16px;
            line-height: 1.4;
        }
    }

    &__box {
        width: 100%;
        height: 56px;
    }

    &._textarea &__box {
        height: 120px;
    }

    &._grey .input {
        background: $colorBackMain;
    }
}
