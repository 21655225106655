$colorDark: #1a2c41;
$colorMain: #e35205;
$colorBackMain: #f5f7fa;
$colorError: #df4646;
$colorGrey: #9a9b9d;
$colorSuccess: #4db733;
$colorMedium: #ed8b00;
$mediaM: 700px;
$mediaMEnd: calc($mediaM + 1px);
$trans: all 0.3s ease-in-out;
