.login {
    width: 100%;
    height: 100%;
    background: $colorBackMain;

    &__block {
        height: 100%;

        @include mob {
            height: auto;
        }

        &._content {
            flex: 1 auto;
        }
    }

    &__banner {
        display: block;
        height: 100%;
    }

    &__form {
        width: 100%;
        max-width: 486px;

        &Title {
            width: 100%;
            margin-bottom: 30px;

            @include mob {
                text-align: center;
            }
        }

        &Description {
            width: 100%;
            margin-bottom: 30px;

            @include mob {
                text-align: center;
            }
        }

        &Fields {
            width: 100%;
            margin-bottom: 40px;
        }

        &Field {
            width: 100%;

            & + & {
                margin-top: 30px;

                @include mob {
                    margin-top: 20px;
                }
            }
        }

        &Button {
            width: 100%;
            height: 66px;
            margin-bottom: 30px;

            @include mob {
                height: 56px;
                margin-bottom: 20px;
            }
        }

        &Forget {
            width: 100%;
        }
    }
}
