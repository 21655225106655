.button {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 11px 20px;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    background: $colorMain;
    border: 2px solid transparent;
    border-radius: 16px;

    &._withIcon {
        padding-right: 10px;
        padding-left: 10px;

        &._left {
            padding-left: 32px;
        }
    }

    @include mob {
        font-size: 16px;
        line-height: 1.3;
    }

    @include button;

    &._empty {
        color: $colorDark;
        background: none;
        border-color: $colorMain;
    }

    &__loader {
        z-index: 10;
        background: inherit;
        border-radius: inherit;
    }

    & i {
        width: 24px;
        height: 24px;

        @include icon(#fff);

        &._start {
            position: absolute;
            top: 50%;
            left: 16px;
            transform: translate(0, -50%);
        }
    }
}
