.indexAbout {
    position: relative;
    padding: 6rem 0 20rem;

    @include mob {
        padding: 50rem 0 30rem;
    }

    &__head {
        position: relative;
        z-index: 1;
        width: 75%;
        margin: 0 auto 110rem;
        padding: 48rem 80rem 60rem;
        background: #f22830;
        border-radius: 64rem;

        @include mob {
            width: 100%;
            margin-bottom: 86rem;
            padding: 36rem 32rem 50rem;
            border-radius: 60rem;
        }

        &::after {
            position: absolute;
            top: 100%;
            left: -58rem;
            z-index: 3;
            width: 514rem;
            height: 690rem;
            margin-top: -24rem;
            content: "";
            pointer-events: none;

            @include bg("/media/index-about-start-line.svg", contain, left top);

            @include mob {
                @include bg("/media/index-about-start-line-mob.svg", contain, center top);

                left: 0;
                width: 208rem;
                margin-top: -20rem;
            }
        }

        &::before {
            position: absolute;
            top: -42rem;
            right: 20rem;
            z-index: -1;
            width: 216rem;
            height: 128rem;
            content: "";

            @include bg("/media/about-decor-1.svg", contain, right top);

            @include mob {
                top: -32rem;
                right: 19rem;
                width: 186rem;
                height: 106rem;
            }
        }

        &Title {
            margin-bottom: 12rem;
            color: #ffd100;
            font-weight: 600;
            font-size: 32rem;
            text-align: center;

            @include mob {
                margin-bottom: 14rem;
                font-weight: 500;
                font-size: 26rem;
                line-height: 1.3;
            }
        }

        &Description {
            color: #fff;
            font-size: 20rem;
            line-height: 1.35;
            text-align: center;

            @include mob {
                line-height: 1.3;
            }
        }
    }

    &__mainPrize {
        position: relative;
        z-index: 3;
        width: 77%;
        height: 464rem;
        margin: 0 auto 99rem;

        @include mob {
            width: 88%;
            height: auto;
            margin-bottom: 80rem;
        }

        &Inner {
            position: relative;
            z-index: 3;
            padding: 60rem;
            overflow: hidden;
            background: #ff252e;
            border-radius: 44rem;

            @include mob {
                padding: 270rem 20rem 50rem;
                border-radius: 34rem;
            }

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                content: "";
                pointer-events: none;

                @include bg("/media/main-prize-1-1.png", auto 100%, left center);

                @include mob {
                    @include bg("/media/main-prize-1-1-mob.png", 100% auto, center top);
                }
            }

            &::after {
                position: absolute;
                top: -52rem;
                right: -190rem;
                z-index: -1;
                width: 739rem;
                height: 620rem;
                animation: 2s infinite mainPrizeBack ease-in-out;
                content: "";

                @include bg("/media/index-mainPrize-back.svg", contain, right top);

                @include mob {
                    width: 420rem;
                    height: 420rem;
                    background-position: left bottom;
                    inset: auto auto -90rem -60rem;
                }

                @keyframes mainPrizeBack {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(1.17);
                    }

                    100% {
                        transform: scale(1);
                    }
                }
            }
        }

        &Content {
            width: 244rem;
            margin: auto 0 auto auto;
            padding-bottom: 68rem;

            @include mob {
                margin: 0 auto;
                padding-bottom: 0;
            }
        }

        &Title {
            margin-bottom: 12rem;
            color: #ffd100;
            font-weight: 600;
            font-size: 36rem;
            line-height: 1.3;
            text-align: center;
            text-transform: uppercase;

            @include mob {
                margin-bottom: 8rem;
                font-size: 26rem;
            }
        }

        &Description {
            color: #fff;
            font-weight: 600;
            font-size: 24rem;
            line-height: 1.3;
            text-align: center;
            text-transform: uppercase;

            @include mob {
                font-size: 18rem;
                line-height: 1.35;
            }
        }

        &Decor {
            position: absolute;
            z-index: 4;
        }

        &Decor._1 {
            top: 31rem;
            right: 327rem;
            width: 31rem;
            height: 33rem;

            @include bg("/media/index-mainPrize-decor-1.svg", contain, left top);

            animation: 2s mainPrizeDecor1 infinite ease-in-out;

            @include mob {
                inset: auto auto 150rem 26rem;
                width: 28rem;
                height: 28rem;
            }

            @keyframes mainPrizeDecor1 {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate(-40rem, -40rem);
                    opacity: 0;
                }
            }
        }

        &Decor._2 {
            top: 125rem;
            right: 296rem;
            width: 42rem;
            height: 54rem;

            @include bg("/media/index-mainPrize-decor-2.svg", contain, left top);

            animation: 2s mainPrizeDecor2 infinite ease-in-out;

            @include mob {
                inset: auto auto 24rem 28rem;
                width: 24rem;
                height: 24rem;
            }

            @keyframes mainPrizeDecor2 {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate(-30rem, -15rem);
                    opacity: 0;
                }
            }
        }

        &Decor._3 {
            top: 129rem;
            right: 27rem;
            width: 26rem;
            height: 21rem;

            @include bg("/media/index-mainPrize-decor-3.svg", contain, left top);

            animation: 2s mainPrizeDecor3 infinite ease-in-out;

            @include mob {
                top: auto;
                right: 14rem;
                bottom: 120rem;
                width: 23rem;
                height: 20rem;
            }

            @keyframes mainPrizeDecor3 {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate(20rem, -45rem);
                    opacity: 0;
                }
            }
        }

        &Decor._4 {
            top: 172rem;
            right: 38rem;
            width: 32rem;
            height: 32rem;

            @include bg("/media/index-mainPrize-decor-4.svg", contain, left top);

            animation: 2s mainPrizeDecor4 infinite linear;

            @include mob {
                top: auto;
                right: 42rem;
                bottom: 154rem;
                width: 24rem;
                height: 24rem;
            }

            @keyframes mainPrizeDecor4 {
                0% {
                    transform: translate(0, 0) rotate(0deg);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate(30rem, -20rem) rotate(360deg);
                    opacity: 0;
                }
            }
        }

        &Decor._5 {
            right: 61rem;
            bottom: 92rem;
            width: 35rem;
            height: 47rem;

            @include bg("/media/index-mainPrize-decor-5.svg", contain, left top);

            animation: 2s mainPrizeDecor5 infinite ease-in-out;

            @include mob {
                right: 43rem;
                bottom: 18rem;
                width: 26rem;
                height: 32rem;
            }

            @keyframes mainPrizeDecor5 {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate(20rem, 45rem);
                    opacity: 0;
                }
            }
        }

        &Decor._6 {
            right: 148rem;
            bottom: 82rem;
            width: 28rem;
            height: 25rem;

            @include bg("/media/index-mainPrize-decor-6.svg", contain, left top);

            animation: 2s mainPrizeDecor6 infinite ease-in-out;

            @include mob {
                right: 164rem;
                bottom: 10rem;
                width: 20rem;
                height: 20rem;
            }

            @keyframes mainPrizeDecor6 {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate(-20rem, 45rem);
                    opacity: 0;
                }
            }
        }
    }

    &__headDecor {
        position: absolute;
    }

    &__headDecor._1 {
        top: 70rem;
        left: 9rem;
        width: 25rem;
        height: 24rem;

        @include bg("/media/index-about-decor-1.svg", contain, left bottom);

        animation: 2s headDecor1 infinite ease-in-out;

        @include mob {
            top: 40rem;
        }

        @keyframes headDecor1 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(-20rem, -20rem);
                opacity: 0;
            }
        }
    }

    &__headDecor._2 {
        top: 114rem;
        left: 27rem;
        width: 66rem;
        height: 52rem;

        @include bg("/media/index-about-decor-2.svg", contain, left bottom);

        animation: 2s headDecor2 infinite ease-in-out;

        @include mob {
            top: 90rem;
            left: -20rem;
            width: 50rem;
            height: 40rem;
        }

        @keyframes headDecor2 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(-30rem, 40rem);
                opacity: 0;
            }
        }
    }

    &__headDecor._3 {
        top: 46rem;
        right: 52rem;
        width: 18rem;
        height: 23rem;

        @include bg("/media/index-about-decor-3.svg", contain, right bottom);

        animation: 2s headDecor3 infinite ease-in-out;

        @include mob {
            top: 26rem;
            right: 32rem;
        }

        @keyframes headDecor3 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(30rem, -40rem);
                opacity: 0;
            }
        }
    }

    &__headDecor._4 {
        top: 90rem;
        right: 9rem;
        width: 19rem;
        height: 18rem;

        @include bg("/media/index-about-decor-4.svg", contain, right bottom);

        animation: 2s headDecor4 infinite ease-in-out;

        @include mob {
            top: 92rem;
            right: -7rem;
            width: 20rem;
            height: 20rem;
        }

        @keyframes headDecor4 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(25rem, -5rem);
                opacity: 0;
            }
        }
    }

    &__headDecor._5 {
        top: 128rem;
        right: 51rem;
        width: 24rem;
        height: 30rem;

        @include bg("/media/index-about-decor-5.svg", contain, right bottom);

        animation: 2s headDecor5 infinite ease-in-out;

        @include mob {
            top: 150rem;
            right: -4rem;
        }

        @keyframes headDecor5 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(25rem, 30rem);
                opacity: 0;
            }
        }
    }

    &__decor {
        position: absolute;
    }

    &__decor._1 {
        top: -28rem;
        left: 187rem;
        z-index: 2;
        display: flex;
        width: 104rem;
        height: 104rem;
        background: repeating-linear-gradient(#fff, #fff 2rem, #e11f26 2rem, #e11f26 7rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);

        @include mob {
            top: -32rem;
            left: 156rem;
            width: 54rem;
            height: 54rem;
            background: repeating-linear-gradient(
                rgba(#fff, 0.8),
                rgba(#fff, 0.8) 1.5rem,
                #e11f26 1.5rem,
                #e11f26 4.5rem
            );
        }

        &::before {
            width: 48rem;
            height: 48rem;
            margin: auto;
            background: #e11f26;
            border-radius: 50%;
            content: "";

            @include mob {
                width: 24rem;
                height: 24rem;
            }
        }

        @include desk {
            animation: 4s infinite indexDecor1 linear;

            @keyframes indexDecor1 {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 104rem 104rem;
                }
            }
        }

        @include mob {
            animation: 3s infinite indexDecorMob1 linear;

            @keyframes indexDecorMob1 {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 54rem 54rem;
                }
            }
        }
    }

    &__decor._2 {
        top: 158rem;
        left: 78rem;
        width: 146rem;
        height: 86rem;
        opacity: 0.64;

        @include bg("/media/about-decor-1.svg", contain, left bottom);

        @include mob {
            top: 3rem;
            left: 58rem;
            width: 54rem;
            height: 30rem;
        }
    }

    &__decor._3 {
        top: 144rem;
        left: 50rem;
        z-index: 3;
        width: 53rem;
        height: 53rem;
        animation: 8s infinite indexDecor3 linear;

        @include bg("/media/light-anima-2.svg", contain, left bottom);

        @include mob {
            top: -6rem;
            left: 50rem;
            width: 26rem;
            height: 26rem;
        }

        @keyframes indexDecor3 {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    &__decor._4 {
        top: 68rem;
        right: 283rem;
        z-index: 3;
        width: 20rem;
        height: 20rem;

        @include bg("/media/crest-element.svg", contain, left bottom);

        @include mob {
            top: -36rem;
            right: 110rem;
            width: 10rem;
            height: 10rem;
        }
    }

    &__decor._5 {
        top: -82rem;
        right: 130rem;
        z-index: 3;
        width: 75rem;
        height: 48rem;

        @include bg("/media/cloud-2.svg", contain, left bottom);

        @include mob {
            top: -60rem;
            right: 40rem;
            width: 42rem;
            height: 28rem;
        }
    }

    &__decor._6 {
        top: 124rem;
        right: 226rem;
        z-index: 3;
        width: 40rem;
        height: 27rem;

        @include bg("/media/cloud-3.svg", contain, left bottom);

        @include mob {
            display: none;
        }
    }

    &__decor._7 {
        top: 64rem;
        right: -64rem;
        z-index: 3;
        width: 165rem;
        height: 60rem;

        @include bg("/media/wave-1-anima.svg", contain, left bottom);

        animation: 2s infinite indexDecor7 ease-in-out;

        @include mob {
            top: -10rem;
            width: 90rem;
            height: 44rem;
        }

        @keyframes indexDecor7 {
            0% {
                transform: scaleX(1);
            }

            50% {
                transform: scaleX(1.2);
            }

            100% {
                transform: scaleX(1);
            }
        }
    }

    &__decor._8 {
        top: 192rem;
        right: 64rem;
        z-index: 3;
        width: 80rem;
        height: 94rem;
        animation: 2s infinite indexDecor4 ease-in-out;

        @include bg("/media/truba-anima.svg", contain, left bottom);

        @include mob {
            top: 350rem;
            right: 16rem;
            width: 54rem;
            height: 68rem;
        }

        @keyframes indexDecor4 {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.17);
            }

            100% {
                transform: scale(1);
            }
        }
    }

    &__decor._9 {
        top: 223rem;
        right: -67rem;
        z-index: 2;
        display: flex;
        width: 188rem;
        height: 188rem;
        background: repeating-linear-gradient(#f22830, #f22830 10rem, #e11f26 10rem, #e11f26 13.5rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);
        opacity: 0.64;
        animation: 6s infinite indexDecor5 linear;

        @include mob {
            top: 370rem;
            right: -64rem;
            width: 120rem;
            height: 120rem;
            background: repeating-linear-gradient(#f22830, #f22830 5rem, #e11f26 5rem, #e11f26 8.5rem);
            opacity: 0.5;
        }

        &::before {
            width: 90rem;
            height: 90rem;
            margin: auto;
            background: #e11f26;
            border-radius: 50%;
            content: "";

            @include mob {
                width: 46rem;
                height: 46rem;
            }
        }

        @keyframes indexDecor5 {
            0% {
                background-position: 0 0;
            }

            100% {
                background-position: 188rem 188rem;
            }
        }
    }

    &__map {
        position: relative;
        z-index: 1;
        width: calc(100% + 196rem);
        margin-left: -98rem;
        overflow: hidden;
        background: #fcdbc6;
        border-radius: 64rem;

        @include mob {
            width: calc(100% + 42rem);
            margin-left: -21rem;
            border-radius: 42rem;
        }

        &Block {
            position: relative;
            z-index: -1;
            padding: 0 168rem;

            @include mob {
                padding: 0 22rem;
            }

            &._main {
                height: 1444rem;

                @include bg("/media/map-back-1.svg", 100% auto, center top);

                @include mob {
                    @include bg("/media/map-back-1-mob.svg", 100% auto, center top);

                    height: 2270rem;
                }
            }

            &._news {
                height: 666rem;

                @include bg("/media/map-back-2.svg", 100% auto, center top);

                @include mob {
                    @include bg("/media/map-back-2-mob.svg", 100% auto, center top);

                    height: 936rem;
                }
            }

            &._prizes {
                @include bg("/media/map-back-3.svg", 100% auto, center top);

                @include mob {
                    @include bg("/media/map-back-3-mob.svg", 100% auto, center top);
                }
            }

            &._rules {
                @include bg("/media/map-back-4.svg", 100% auto, center top);

                @include mob {
                    @include bg("/media/map-back-4-mob.svg", 100% auto, center -30rem);
                }
            }
        }

        &NavItem {
            position: absolute;
            z-index: 2;

            &Head {
                width: 250rem;
                height: 250rem;

                @include mob {
                    width: 192rem;
                    height: 192rem;
                }
            }

            &Image {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transition: $trans;
                will-change: transform;
            }

            @include desk {
                &:hover &Image {
                    transform: scale(1.05);
                }
            }

            &Content {
                color: #222221;
                font-weight: 700;
                font-size: 18rem;
                line-height: 1.3;
                text-align: center;
                text-transform: uppercase;

                @include mob {
                    font-weight: 600;
                }
            }
        }

        &Nav &NavItem:nth-child(1) {
            top: 104rem;
            left: 104rem;

            @include mob {
                top: 48rem;
                left: 36rem;
            }
        }

        &Nav &NavItem:nth-child(1) &NavItemContent {
            margin: 18rem 0 0 -13rem;

            @include mob {
                margin: 10rem 0 0 -8rem;
            }
        }

        &Nav &NavItem:nth-child(2) {
            top: 50rem;
            left: 538rem;

            @include mob {
                top: 306rem;
                right: -2rem;
                left: auto;
            }
        }

        &Nav &NavItem:nth-child(2) &NavItemHead {
            @include mob {
                width: 226rem;
                height: 226rem;
            }
        }

        &Nav &NavItem:nth-child(2) &NavItemContent {
            margin: -11rem 0 0 18rem;

            @include mob {
                width: 150rem;
                margin: -9rem 0 0 45rem;
                text-align: center;
            }
        }

        &Nav &NavItem:nth-child(3) {
            top: 154rem;
            right: 129rem;

            @include mob {
                top: 524rem;
                right: auto;
                left: 2rem;
            }
        }

        &Nav &NavItem:nth-child(3) &NavItemHead {
            @include mob {
                width: 226rem;
                height: 226rem;
            }
        }

        &Nav &NavItem:nth-child(3) &NavItemContent {
            margin: -3rem 0 0 14rem;

            @include mob {
                width: 150rem;
                margin: -7rem 0 0 40rem;
                text-align: center;
            }
        }

        &Nav &NavItem:nth-child(4) {
            top: 452rem;
            left: 232rem;

            @include mob {
                top: 820rem;
                right: 28rem;
                left: auto;
            }
        }

        &Nav &NavItem:nth-child(4) &NavItemHead {
            @include mob {
                width: 240rem;
                height: 240rem;
            }
        }

        &Nav &NavItem:nth-child(4) &NavItemContent {
            margin: -15rem 0 0 14rem;

            @include mob {
                margin-left: 6rem;
            }
        }

        &Nav &NavItem:nth-child(5) {
            top: 590rem;
            right: 294rem;

            @include mob {
                top: 1104rem;
                right: auto;
                left: 26rem;
            }
        }

        &Nav &NavItem:nth-child(5) &NavItemHead {
            @include mob {
                width: 216rem;
                height: 216rem;
            }
        }

        &Nav &NavItem:nth-child(5) &NavItemContent {
            margin: -9rem 0 0 -5rem;
        }

        &Nav &NavItem:nth-child(6) {
            top: 800rem;
            left: 472rem;

            @include mob {
                top: 1394rem;
                right: 22rem;
                left: auto;
            }
        }

        &Nav &NavItem:nth-child(6) &NavItemHead {
            @include mob {
                width: 216rem;
                height: 216rem;
            }
        }

        &Nav &NavItem:nth-child(6) &NavItemContent {
            margin: 5rem 0 0 48rem;

            @include mob {
                margin: -10rem 0 0 -190rem;
            }
        }

        &Nav &NavItem:nth-child(7) {
            top: 937rem;
            right: 90rem;

            @include mob {
                top: 1648rem;
                right: auto;
                left: -4rem;
            }
        }

        &Nav &NavItem:nth-child(7) &NavItemHead {
            @include mob {
                width: 242rem;
                height: 242rem;
            }
        }

        &Nav &NavItem:nth-child(7) &NavItemContent {
            margin: -2rem 0 0;

            @include mob {
                margin: -130rem 0 0 240rem;
            }
        }

        &Nav &NavItem:nth-child(8) {
            top: 1066rem;
            left: 138rem;

            @include mob {
                top: 1886rem;
                right: 18rem;
                left: auto;
            }
        }

        &Nav &NavItem:nth-child(8) &NavItemHead {
            @include mob {
                width: 244rem;
                height: 244rem;
            }
        }

        &Nav &NavItem:nth-child(8) &NavItemContent {
            margin: -15rem 0 0 20rem;

            @include mob {
                width: 120rem;
                margin: -22rem 0 0 73rem;
                text-align: center;
            }
        }

        &NavItem._cheque {
            bottom: 90rem;
            left: 50%;
            transform: translate(-50%, 0);

            @include mob {
                top: 396rem;
                bottom: auto;
            }
        }

        &NavItem._cheque &NavItemHead {
            @include mob {
                width: 214rem;
                height: 214rem;
            }
        }

        &NavItem._cheque &NavItemContent {
            margin-top: 10rem;

            @include mob {
                width: 150rem;
                margin: 50rem auto 0;
                text-align: center;
            }
        }

        &News {
            position: relative;
            padding: 64rem 170rem;
            background: #5d279e;
            border-radius: 64rem;

            @include mob {
                width: 100%;
                padding: 180rem 30rem 120rem;
                border-radius: 54rem;
            }

            &Bottles {
                position: absolute;
                top: -54rem;
                left: 59rem;
                z-index: 2;
                width: 186rem;
                height: 258rem;

                @include mob {
                    top: -50rem;
                    left: 34rem;
                    width: 150rem;
                    height: 200rem;
                }
            }

            &Bottle {
                position: absolute;
                object-fit: contain;

                @keyframes bottleMove {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(0, -4rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                animation: 2s bottleMove infinite ease-in-out;

                &._1 {
                    top: 0;
                    left: 0;
                    z-index: 2;
                    width: 128rem;
                    height: 244rem;
                    animation-delay: 0.3s;

                    @include mob {
                        height: 200rem;
                    }
                }

                &._2 {
                    top: 74rem;
                    left: 80rem;
                    z-index: 1;
                    width: 108rem;
                    height: 189rem;
                    animation-delay: 0.6s;

                    @include mob {
                        top: 58rem;
                        left: 80rem;
                        width: 90rem;
                        height: 160rem;
                    }
                }
            }

            &Bottles._navItem {
                top: -6rem;
                right: -16rem;
                left: auto;
                width: 116rem;
                height: 160rem;

                @include mob {
                    top: -4rem;
                    right: -2rem;
                    width: 90rem;
                    height: 130rem;
                }
            }

            &Bottles._navItem &Bottle {
                &._1 {
                    width: 76rem;
                    height: 156rem;

                    @include mob {
                        width: 65rem;
                        height: 135rem;
                    }
                }

                &._2 {
                    top: 48rem;
                    left: 49rem;
                    width: 67rem;
                    height: 117rem;

                    @include mob {
                        left: 42rem;
                        width: 55rem;
                        height: 100rem;
                    }
                }
            }

            &Title {
                margin-bottom: 19rem;
                color: #ffd100;
                font-weight: 600;
                font-size: 32rem;
                text-align: center;

                @include mob {
                    max-width: 70%;
                    margin: 0 auto 14rem;
                    font-size: 26rem;
                    line-height: 1.3;
                    text-align: center;
                }
            }

            &Description {
                color: #fff;
                font-weight: 500;
                font-size: 20rem;
                line-height: 1.3;
                text-align: center;

                @include mob {
                    font-size: 20.5rem;
                }
            }

            &Decor {
                position: absolute;
                z-index: 3;
                opacity: 0;
                animation: 2s infinite indexMapDecor ease-in-out;

                &Inner {
                    animation: 2s infinite indexMapDecorRotate ease-in-out;

                    @keyframes indexMapDecorRotate {
                        0% {
                            transform: rotate(15deg);
                        }

                        50% {
                            transform: rotate(-15deg);
                        }

                        100% {
                            transform: rotate(15deg);
                        }
                    }
                }

                @keyframes indexMapDecor {
                    0% {
                        transform: translate(0, 0);
                        opacity: 0;
                    }

                    20% {
                        opacity: 1;
                    }

                    80% {
                        opacity: 1;
                    }

                    100% {
                        transform: translate(0, -60rem);
                        opacity: 0;
                    }
                }
            }

            &Decor._1 {
                top: 46rem;
                left: 26rem;
                width: 22rem;
                height: 21rem;

                @include mob {
                    top: 64rem;
                    left: 20rem;
                }
            }

            &Decor._1 &DecorInner {
                @include bg("/media/index-map-news-decor-1.svg", contain, center);
            }

            &Decor._2 {
                top: 85rem;
                left: 49rem;
                width: 8rem;
                height: 8rem;
                animation-delay: 0.2s;

                @include mob {
                    left: 210rem;
                }
            }

            &Decor._2 &DecorInner {
                border: 2rem solid #ffd100;
                border-radius: 50%;
            }

            &Decor._3 {
                top: 101rem;
                left: 8rem;
                width: 33rem;
                height: 42rem;
                animation-delay: 0.4s;

                @include mob {
                    left: -2rem;
                    width: 31rem;
                    height: 40rem;
                }
            }

            &Decor._3 &DecorInner {
                @include bg("/media/index-map-news-decor-3.svg", contain, center);
            }

            &Decor._4 {
                bottom: 40rem;
                left: 240rem;
                width: 16rem;
                height: 16rem;
                animation-delay: 0.2s;

                @include mob {
                    top: 140rem;
                    bottom: auto;
                    left: 190rem;
                    width: 12rem;
                    height: 12rem;
                }
            }

            &Decor._4 &DecorInner {
                border: 3rem solid #71c200;
                border-radius: 50%;
            }

            &Decor._5 {
                right: 86rem;
                bottom: 57rem;
                width: 57rem;
                height: 44rem;
                animation-delay: 0.4s;

                @include mob {
                    top: 0;
                    right: 96rem;
                    bottom: auto;
                    z-index: 5;
                    width: 40rem;
                    height: 40rem;
                }
            }

            &Decor._5 &DecorInner {
                @include bg("/media/index-map-news-decor-5.svg", contain, center);
            }

            &Decor._6 {
                right: 28rem;
                bottom: 103rem;
                width: 22rem;
                height: 24rem;
                animation-delay: 0.2s;

                @include mob {
                    right: auto;
                    bottom: 80rem;
                    left: 42rem;
                    width: 24rem;
                    height: 26rem;
                }
            }

            &Decor._6 &DecorInner {
                @include bg("/media/index-map-news-decor-6.svg", contain, center);
            }

            &Decor._7 {
                top: 89rem;
                right: 56rem;
                width: 17rem;
                height: 23rem;

                @include mob {
                    top: 184rem;
                    right: -4rem;
                    width: 20rem;
                    height: 26rem;
                }
            }

            &Decor._7 &DecorInner {
                @include bg("/media/index-map-news-decor-7.svg", contain, center);
            }

            &Circle {
                position: absolute;
                top: -46rem;
                right: 65rem;
                z-index: 2;
                width: 160rem;
                height: 160rem;
                background: #10cfc9;
                border-radius: 50%;
                animation: 2s infinite indexMapCircle ease-in-out;

                @include mob {
                    top: -4rem;
                    right: -4rem;
                    width: 130rem;
                    height: 130rem;
                }

                @keyframes indexMapCircle {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(0, -10rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }
            }
        }

        &Prizes {
            &Content {
                position: relative;
                z-index: 1;
                margin-bottom: 96rem;
                padding: 82rem 180rem 70rem;
                overflow: hidden;
                background: #ff49b8;
                border-radius: 64rem;

                @include mob {
                    margin-bottom: 24rem;
                    padding: 126rem 30rem 230rem;
                    border-radius: 56rem;
                }

                &::before {
                    position: absolute;
                    top: -71rem;
                    left: -36rem;
                    z-index: -1;
                    width: 508rem;
                    height: 396rem;
                    content: "";

                    @include bg("/media/index-map-prizes-star.svg", contain, left top);

                    @include desk {
                        animation: 2s infinite mapPrizesStar ease-in-out;

                        @keyframes mapPrizesStar {
                            0% {
                                transform: scale(1);
                            }

                            50% {
                                transform: scale(1.2);
                            }

                            100% {
                                transform: scale(1);
                            }
                        }
                    }

                    @include mob {
                        top: -90rem;
                        left: -130rem;
                        width: 630rem;
                        height: 490rem;
                        transform: rotate(-20deg);
                        animation: 2s infinite mapPrizesStarMob ease-in-out;

                        @keyframes mapPrizesStarMob {
                            0% {
                                transform: rotate(-20deg) scale(1);
                            }

                            50% {
                                transform: rotate(-20deg) scale(1.2);
                            }

                            100% {
                                transform: rotate(-20deg) scale(1);
                            }
                        }
                    }
                }

                &Title {
                    margin-bottom: 15rem;
                    color: #5d279e;
                    font-weight: 600;
                    font-size: 32rem;
                    text-align: center;

                    @include mob {
                        font-size: 26rem;
                        line-height: 1.3;
                    }
                }

                &Description {
                    max-width: 70%;
                    color: #fff;
                    font-weight: 500;
                    font-size: 20rem;
                    line-height: 1.3;
                    text-align: center;

                    @include mob {
                        max-width: 90%;
                    }
                }

                &Decor {
                    position: absolute;
                    transform: rotate(-10deg);
                    animation: 2s infinite mapPrizesContentDecor ease-in-out;

                    @keyframes mapPrizesContentDecor {
                        0% {
                            transform: rotate(-10deg) scale(1);
                        }

                        50% {
                            transform: rotate(10deg) scale(1.2);
                        }

                        100% {
                            transform: rotate(-10deg) scale(1);
                        }
                    }
                }

                &Decor._1 {
                    top: 42rem;
                    left: 134rem;
                    width: 70rem;
                    height: 94rem;

                    @include bg("/media/index-map-prizes-decor-1.svg", contain, left top);

                    @include mob {
                        left: 158rem;
                        width: 50rem;
                        height: 74rem;
                    }
                }

                &Decor._2 {
                    top: 115rem;
                    left: 82rem;
                    width: 35rem;
                    height: 38rem;
                    animation-delay: 0.2s;

                    @include mob {
                        top: 62rem;
                        left: 66rem;
                    }

                    @include bg("/media/index-map-prizes-decor-2.svg", contain, left top);
                }

                &Decor._3 {
                    bottom: 51rem;
                    left: 192rem;
                    width: 18rem;
                    height: 23rem;
                    animation-delay: 0.4s;

                    @include mob {
                        inset: 70rem 66rem auto auto;
                        width: 24rem;
                        height: 27rem;
                    }

                    @include bg("/media/index-map-prizes-decor-3.svg", contain, left top);
                }

                &Card {
                    position: absolute;
                    top: 50%;
                    right: 38rem;
                    z-index: 2;
                    width: 170rem;
                    height: 170rem;
                    transform: translate(0, -50%);

                    @include mob {
                        inset: auto auto 24rem 50%;
                        transform: translate(-50%, 0);
                    }

                    &Image {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    &Inner {
                        position: relative;
                        z-index: 2;
                        background: #f837ad;
                        border-radius: 50%;
                        animation: 2s infinite mapPrizesCard ease-in-out;

                        @keyframes mapPrizesCard {
                            0% {
                                transform: translate(0, 0);
                            }

                            50% {
                                transform: translate(0, -6rem);
                            }

                            100% {
                                transform: translate(0, 0);
                            }
                        }
                    }

                    &Decor {
                        position: absolute;
                        z-index: 3;
                        opacity: 0;
                        animation: 2s infinite indexMapPrizesDecor ease-in-out;

                        &Inner {
                            animation: 2s infinite indexMapPrizesDecorRotate ease-in-out;

                            @keyframes indexMapPrizesDecorRotate {
                                0% {
                                    transform: rotate(10deg);
                                }

                                50% {
                                    transform: rotate(-10deg);
                                }

                                100% {
                                    transform: rotate(10deg);
                                }
                            }
                        }

                        @keyframes indexMapPrizesDecor {
                            0% {
                                transform: translate(0, 0);
                                opacity: 0;
                            }

                            20% {
                                opacity: 1;
                            }

                            80% {
                                opacity: 1;
                            }

                            100% {
                                transform: translate(0, -30rem);
                                opacity: 0;
                            }
                        }
                    }

                    &Decor._1 {
                        top: 11rem;
                        left: 37rem;
                        width: 22rem;
                        height: 32rem;
                    }

                    &Decor._1 &DecorInner {
                        @include bg("/media/index-map-prizes-decor-4.svg", contain, left top);
                    }

                    &Decor._2 {
                        top: 9rem;
                        right: 63rem;
                        width: 25rem;
                        height: 22rem;
                        animation-delay: 0.2s;
                    }

                    &Decor._2 &DecorInner {
                        @include bg("/media/index-map-prizes-decor-5.svg", contain, left top);
                    }

                    &Decor._3 {
                        right: 43rem;
                        bottom: 20rem;
                        width: 23rem;
                        height: 29rem;
                        animation-delay: 0.4s;
                    }

                    &Decor._3 &DecorInner {
                        @include bg("/media/index-map-prizes-decor-6.svg", contain, left top);
                    }

                    &Decor._4 {
                        bottom: 20rem;
                        left: 79rem;
                        width: 13rem;
                        height: 12rem;
                        animation-delay: 0.1s;
                    }

                    &Decor._4 &DecorInner {
                        @include bg("/media/index-map-prizes-decor-7.svg", contain, left top);
                    }
                }
            }

            &Cards {
                width: calc(100% + 200rem);
                padding-bottom: 204rem;

                @include mob {
                    flex-wrap: wrap;
                    width: calc(100% + 10rem);
                    margin-left: -9rem;
                    padding-bottom: 250rem;
                }
            }

            &Card {
                width: 25%;

                @include mob {
                    width: 50%;
                    margin-bottom: 28rem;
                }

                @include desk {
                    &:nth-child(2) {
                        transform: translate(-10rem, 0);
                    }

                    &:nth-child(3) {
                        transform: translate(10rem, 0);
                    }
                }

                &Head {
                    width: 280rem;
                    height: 280rem;
                    margin-bottom: -10rem;
                    transition: $trans;
                    will-change: transform;

                    @include desk {
                        &:hover  {
                            transform: scale(1.05);
                        }
                    }

                    @include mob {
                        width: 196rem;
                        height: 196rem;
                    }
                }

                &Content {
                    font-weight: 700;
                    font-size: 18rem;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }

        &Rules {
            position: relative;
            z-index: 1;
            margin-bottom: 56rem;
            padding: 92rem 166rem 57rem;
            background: #e11f26;
            border-radius: 64rem;

            @include mob {
                z-index: 2;
                margin-bottom: 0;
                padding: 96rem 32rem;
                border-radius: 56rem;
            }

            &Logo {
                position: absolute;
                top: -28rem;
                left: 50%;
                z-index: -1;
                width: 122rem;
                height: 122rem;
                background: #e11f26;
                border-radius: 50%;
                transform: translate(-50%, 0);
                animation: 2s infinite mapRulesLogo ease-in-out;
                pointer-events: none;

                @include mob {
                    top: -32rem;
                }

                @keyframes mapRulesLogo {
                    0% {
                        transform: translate(-50%, 0) scale(1);
                    }

                    50% {
                        transform: translate(-50%, 0) scale(1.1);
                    }

                    100% {
                        transform: translate(-50%, 0) scale(1);
                    }
                }

                &::before {
                    width: 64rem;
                    height: 64rem;
                    content: "";

                    @include bg("/media/logo-5ka.svg", contain, center center);
                }
            }

            &Title {
                margin-bottom: 19rem;
                color: #ffd100;
                font-weight: 600;
                font-size: 32rem;
                text-align: center;

                @include mob {
                    max-width: 80%;
                    margin-bottom: 14rem;
                    font-size: 26rem;
                    line-height: 1.3;
                }
            }

            &Description {
                max-width: 60%;
                color: #fff;
                font-weight: 500;
                font-size: 20rem;
                line-height: 1.3;
                text-align: center;

                @include mob {
                    max-width: 90%;
                }

                & a {
                    display: inline-block;
                    color: #fff;
                    border-bottom: 1px solid #fff;
                }
            }

            &Decor {
                position: absolute;
                opacity: 0;
            }

            &Decor._1 {
                top: 37rem;
                left: 89rem;
                width: 27rem;
                height: 22rem;

                @include bg("/media/index-map-rules-decor-1.svg", contain, center center);

                @include mob {
                    top: 30rem;
                    right: 40rem;
                    left: auto;
                    animation: 2s infinite mapRulesDecorMob1 ease-in-out;

                    @keyframes mapRulesDecorMob1 {
                        0% {
                            transform: translate(0, 0);
                            opacity: 0;
                        }

                        20% {
                            opacity: 1;
                        }

                        80% {
                            opacity: 1;
                        }

                        100% {
                            transform: translate(40rem, -22rem);
                            opacity: 0;
                        }
                    }
                }

                @include desk {
                    animation: 2s infinite mapRulesDecor1 ease-in-out;

                    @keyframes mapRulesDecor1 {
                        0% {
                            transform: translate(0, 0);
                            opacity: 0;
                        }

                        20% {
                            opacity: 1;
                        }

                        80% {
                            opacity: 1;
                        }

                        100% {
                            transform: translate(-40rem, -22rem);
                            opacity: 0;
                        }
                    }
                }
            }

            &Decor._2 {
                top: 81rem;
                left: 47rem;
                width: 35rem;
                height: 44rem;
                animation: 2s infinite mapRulesDecor2 ease-in-out;
                animation-delay: 0.2s;

                @include bg("/media/index-map-rules-decor-2.svg", contain, center center);

                @include mob {
                    top: 36rem;
                    left: 44rem;
                    width: 30rem;
                    height: 38rem;
                }

                @keyframes mapRulesDecor2 {
                    0% {
                        transform: translate(0, 0);
                        opacity: 0;
                    }

                    20% {
                        opacity: 1;
                    }

                    80% {
                        opacity: 1;
                    }

                    100% {
                        transform: translate(-30rem, -3rem);
                        opacity: 0;
                    }
                }
            }

            &Decor._3 {
                bottom: 67rem;
                left: 82rem;
                width: 67rem;
                height: 52rem;

                @include bg("/media/index-map-rules-decor-3.svg", contain, center center);

                @include mob {
                    inset: 40rem 80rem auto auto;
                    width: 40rem;
                    height: 44rem;
                    animation: 2s infinite mapRulesDecorMob3 ease-in-out;
                    animation-delay: 0.3s;

                    @keyframes mapRulesDecorMob3 {
                        0% {
                            transform: translate(0, 0);
                            opacity: 0;
                        }

                        20% {
                            opacity: 1;
                        }

                        80% {
                            opacity: 1;
                        }

                        100% {
                            transform: translate(-10rem, -20rem);
                            opacity: 0;
                        }
                    }
                }

                @include desk {
                    animation: 2s infinite mapRulesDecor3 ease-in-out;
                    animation-delay: 0.3s;

                    @keyframes mapRulesDecor3 {
                        0% {
                            transform: translate(0, 0);
                            opacity: 0;
                        }

                        20% {
                            opacity: 1;
                        }

                        80% {
                            opacity: 1;
                        }

                        100% {
                            transform: translate(-20rem, 20rem);
                            opacity: 0;
                        }
                    }
                }
            }

            &Decor._4 {
                top: 53rem;
                right: 105rem;
                width: 12rem;
                height: 15rem;

                @include bg("/media/index-map-rules-decor-4.svg", contain, center center);

                @include mob {
                    inset: auto auto 30rem 80rem;
                    width: 25rem;
                    height: 25rem;
                    animation: 2s infinite mapRulesDecorMob4 ease-in-out;

                    @keyframes mapRulesDecorMob4 {
                        0% {
                            transform: translate(0, 0);
                            opacity: 0;
                        }

                        20% {
                            opacity: 1;
                        }

                        80% {
                            opacity: 1;
                        }

                        100% {
                            transform: translate(-20rem, 20rem);
                            opacity: 0;
                        }
                    }
                }

                @include desk {
                    animation: 2s infinite mapRulesDecor4 ease-in-out;

                    @keyframes mapRulesDecor4 {
                        0% {
                            transform: translate(0, 0);
                            opacity: 0;
                        }

                        20% {
                            opacity: 1;
                        }

                        80% {
                            opacity: 1;
                        }

                        100% {
                            transform: translate(20rem, -20rem);
                            opacity: 0;
                        }
                    }
                }
            }

            &Decor._5 {
                top: 109rem;
                right: 38rem;
                width: 19rem;
                height: 18rem;

                @include bg("/media/index-map-rules-decor-5.svg", contain, center center);

                @include mob {
                    top: auto;
                    right: 90rem;
                    bottom: 40rem;
                    animation: 2s infinite mapRulesDecorMob5 ease-in-out;
                    animation-delay: 0.2s;

                    @keyframes mapRulesDecorMob5 {
                        0% {
                            transform: translate(0, 0);
                            opacity: 0;
                        }

                        20% {
                            opacity: 1;
                        }

                        80% {
                            opacity: 1;
                        }

                        100% {
                            transform: translate(-5rem, 30rem);
                            opacity: 0;
                        }
                    }
                }

                @include desk {
                    animation: 2s infinite mapRulesDecor5 ease-in-out;
                    animation-delay: 0.2s;

                    @keyframes mapRulesDecor5 {
                        0% {
                            transform: translate(0, 0);
                            opacity: 0;
                        }

                        20% {
                            opacity: 1;
                        }

                        80% {
                            opacity: 1;
                        }

                        100% {
                            transform: translate(20rem, -2rem);
                            opacity: 0;
                        }
                    }
                }
            }

            &Decor._6 {
                right: 86rem;
                bottom: 34rem;
                width: 40rem;
                height: 43rem;
                animation: 2s infinite mapRulesDecor6 ease-in-out;
                animation-delay: 0.3s;

                @include bg("/media/index-map-rules-decor-6.svg", contain, center center);

                @include mob {
                    right: 40rem;
                    bottom: 40rem;
                    width: 24rem;
                    height: 28rem;
                }

                @keyframes mapRulesDecor6 {
                    0% {
                        transform: translate(0, 0);
                        opacity: 0;
                    }

                    20% {
                        opacity: 1;
                    }

                    80% {
                        opacity: 1;
                    }

                    100% {
                        transform: translate(30rem, 20rem);
                        opacity: 0;
                    }
                }
            }
        }

        &MainPrizes {
            position: relative;
            z-index: 1;
            width: calc(100% + 100rem);
            margin-bottom: 196rem;
            margin-left: -50rem;
            padding: 54rem 42rem;
            overflow: hidden;
            background: #faceb3;
            border-radius: 96rem;

            @include mob {
                width: calc(100% + 24rem);
                margin: -170rem 0 304rem -12rem;
                padding: 204rem 20rem 54rem;
                background: rgba(#faceb3, 0.85);
                border-radius: 60rem;
            }

            &Button {
                position: absolute;
                top: 50%;
                z-index: 3;
                width: 48rem;
                height: 48rem;
                margin-top: -12rem;
                background: #e11f26;
                border-radius: 50%;
                transform: translate(0, -50%);

                @include mob {
                    top: auto;
                    bottom: 186rem;
                    width: 40rem;
                    height: 40rem;
                    margin-top: 0;
                    transform: translate(0, 0);
                }

                &._prev {
                    left: 78rem;

                    @include mob {
                        left: 36rem;
                    }
                }

                &._next {
                    right: 78rem;

                    @include mob {
                        right: 36rem;
                    }
                }

                &Icon {
                    width: 20rem;
                    height: 20rem;

                    @include mob {
                        width: 16rem;
                        height: 16rem;
                    }
                }
            }

            &Inner {
                position: relative;
                z-index: 2;
                width: calc(100% - 210rem);

                @include mob {
                    width: calc(100% - 166rem);
                }
            }

            &Items {
                display: inline-flex;
            }

            &Item {
                width: 240rem;
                margin-right: 42rem;
                transition: $trans;

                @include mob {
                    width: 190rem;

                    &:not([data-prev]) {
                        opacity: 0;
                    }
                }

                @include desk {
                    // &:not(._current) {
                    //     opacity: 0;
                    // }

                    &:not([data-prev], [data-next], [data-current]) {
                        opacity: 0;
                    }
                }
            }

            &._drag &Item {
                opacity: 1 !important;
            }
        }

        &MainPrizesItem:not([data-current]) &MainPrize {
            @include desk {
                transform: scale(0.8);
            }
        }

        &MainPrizesItem:not([data-prev]) &MainPrize {
            @include mob {
                transform: scale(0.7);
            }
        }

        &MainPrize {
            transition: $trans;
            will-change: transform;

            &Title {
                margin-bottom: 21rem;
                color: #e11f26;
                font-weight: 600;
                font-size: 24rem;

                @include mob {
                    margin-bottom: 34rem;
                    font-size: 20rem;
                    line-height: 1.3;
                    white-space: nowrap;
                    text-align: center;
                }
            }

            &Head {
                width: 100%;
                height: 240rem;
                margin-bottom: 34rem;
                background: #ff49b8;
                border-radius: 50%;

                @include mob {
                    height: 190rem;
                }
            }

            &Image {
                object-fit: contain;
            }

            &Description {
                font-weight: 600;
                font-size: 18rem;
                line-height: 1.3;
                text-transform: uppercase;
            }
        }
    }

    &__mapMainDecor,
    &__mapMainBackDecor {
        position: absolute;
        z-index: 2;
    }

    &__mapMainBackDecor {
        z-index: -1;
        width: 586rem;
        height: 466rem;
        opacity: 0.2;

        @include mob {
            width: 400rem;
            height: 320rem;
        }

        @include bg("/media/lines-anima.svg", contain, left bottom);
    }

    &__mapMainBackDecor._1 {
        top: 152rem;
        left: -198rem;

        @include mob {
            top: 180rem;
            left: -220rem;
        }
    }

    &__mapMainBackDecor._2 {
        top: 1846rem;
        left: -158rem;

        @include mob {
            top: 1260rem;
            right: -270rem;
            left: auto;
        }
    }

    &__mapMainBackDecor._3 {
        top: 1716rem;
        right: -120rem;

        @include mob {
            top: 1860rem;
            right: -150rem;
        }
    }

    &__mapMainBackDecor._4 {
        top: 3150rem;
        left: -210rem;

        @include mob {
            top: 3050rem;
            left: -70rem;
        }
    }

    &__mapMainBackDecor._5 {
        top: 3588rem;
        left: 387rem;

        @include mob {
            top: auto;
            bottom: -100rem;
            left: -100rem;
        }
    }

    &__mapMainDecor._1 {
        top: 428rem;
        left: 594rem;
        width: 47rem;
        height: 47rem;

        @include bg("/media/bike-1.svg", contain, left bottom);

        animation: 6s infinite bike1 ease-in-out;

        @include mob {
            top: 458rem;
            left: 100rem;
            width: 45rem;
            height: 45rem;
        }

        @keyframes bike1 {
            0% {
                transform: translate(0, 0);
            }

            45% {
                transform: translate(100rem, 0);
            }

            55% {
                transform: translate(100rem, 0) rotateY(180deg);
            }

            90% {
                transform: translate(0, 0) rotateY(180deg);
            }

            100% {
                transform: translate(0, 0);
            }
        }
    }

    &__mapMainDecor._2 {
        top: 647rem;
        left: 594rem;
        width: 40rem;
        height: 50rem;

        @include bg("/media/bike-4.svg", contain, left bottom);

        animation: 4.5s infinite bike2 ease-in-out;
        animation-delay: 0.5s;

        @include mob {
            top: 1666rem;
            left: 200rem;
        }

        @keyframes bike2 {
            0% {
                transform: translate(0, 0);
            }

            45% {
                transform: translate(70rem, 0);
            }

            55% {
                transform: translate(70rem, 0) rotateY(180deg);
            }

            90% {
                transform: translate(0, 0) rotateY(180deg);
            }

            100% {
                transform: translate(0, 0);
            }
        }
    }

    &__mapMainDecor._3 {
        top: 590rem;
        left: 1044rem;
        width: 50rem;
        height: 50rem;

        @include bg("/media/bike-3.svg", contain, left bottom);

        animation: 5s infinite bike3 ease-in-out;

        @include mob {
            top: 1144rem;
            left: 230rem;
            width: 46rem;
            height: 46rem;
        }

        @keyframes bike3 {
            0% {
                transform: translate(0, 0);
            }

            45% {
                transform: translate(50rem, 0);
            }

            55% {
                transform: translate(50rem, 0) rotateY(180deg);
            }

            90% {
                transform: translate(0, 0) rotateY(180deg);
            }

            100% {
                transform: translate(0, 0);
            }
        }
    }

    &__mapMainDecor._4 {
        top: 444rem;
        left: 884rem;
        width: 92rem;
        height: 92rem;

        @include bg("/media/light-anima-1.svg", contain, left bottom);

        animation: 5s infinite mapMainDecor4 linear;

        @include mob {
            top: 420rem;
            left: 45rem;
            width: 70rem;
            height: 70rem;
        }

        @keyframes mapMainDecor4 {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    &__mapMainDecor._5 {
        top: 486rem;
        left: 845rem;
        width: 23rem;
        height: 23rem;
        transform: scale(0);

        @include bg("/media/shine-anima.svg", contain, left bottom);

        animation: 2s infinite mapMainCircle linear;

        @include mob {
            top: 100rem;
            right: 66rem;
            left: 200rem;
            left: auto;
            width: 9rem;
            height: 9rem;
        }

        @keyframes mapMainCircle {
            0% {
                transform: scale(0);
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: scale(2);
                opacity: 0;
            }
        }
    }

    &__mapMainDecor._6 {
        top: 661rem;
        left: 210rem;
        width: 23rem;
        height: 23rem;
        transform: scale(0);

        @include bg("/media/shine-anima.svg", contain, left bottom);

        animation: 2s infinite mapMainCircle linear;
        animation-delay: 0.2s;

        @include mob {
            top: 530rem;
            width: 10rem;
            height: 10rem;
        }
    }

    &__mapMainDecor._7 {
        top: 594rem;
        right: 78rem;
        width: 23rem;
        height: 23rem;
        transform: scale(0);

        @include bg("/media/shine-anima.svg", contain, left bottom);

        animation: 2s infinite mapMainCircle linear;
        animation-delay: 0.7s;

        @include mob {
            top: 1170rem;
            right: 14rem;
            width: 12rem;
            height: 12rem;
        }
    }

    &__mapMainDecor._8 {
        top: 869rem;
        left: 767rem;
        width: 50rem;
        height: 50rem;

        @include bg("/media/bike-5.svg", contain, left bottom);

        animation: 5s infinite bike5 ease-in-out;
        animation-delay: 1s;

        @include mob {
            top: 1957rem;
            left: 30rem;
            width: 46rem;
            height: 46rem;
        }

        @keyframes bike5 {
            0% {
                transform: translate(0, 0);
            }

            45% {
                transform: translate(48rem, 0);
            }

            55% {
                transform: translate(48rem, 0) rotateY(180deg);
            }

            90% {
                transform: translate(0, 0) rotateY(180deg);
            }

            100% {
                transform: translate(0, 0);
            }
        }
    }

    &__mapMainDecor._9 {
        top: 1310rem;
        left: 565rem;
        width: 50rem;
        height: 50rem;

        @include bg("/media/bike-2.svg", contain, left bottom);

        animation: 5s infinite bike9 ease-in-out;
        animation-delay: 0.6s;

        @include mob {
            top: 1530rem;
            left: 130rem;
            width: 44rem;
            height: 44rem;
        }

        @keyframes bike9 {
            0% {
                transform: translate(0, 0);
            }

            45% {
                transform: translate(-90rem, 0);
            }

            55% {
                transform: translate(-90rem, 0) rotateY(180deg);
            }

            90% {
                transform: translate(0, 0) rotateY(180deg);
            }

            100% {
                transform: translate(0, 0);
            }
        }
    }

    &__mapMainDecor._10 {
        top: 916rem;
        left: -68rem;
        z-index: 2;
        display: flex;
        width: 142rem;
        height: 142rem;
        background: repeating-linear-gradient(#fcdbc6, #fcdbc6 2.5rem, #e8251d 2.5rem, #e8251d 9.5rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);

        @include mob {
            left: -50rem;
            width: 100rem;
            height: 100rem;
            background: repeating-linear-gradient(#fcdbc6, #fcdbc6 2rem, #e8251d 2rem, #e8251d 5.9rem);
            animation: 2s infinite mapMainDecorMob10 linear;

            @keyframes mapMainDecorMob10 {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 53rem 53rem;
                }
            }
        }

        &::before {
            width: 70rem;
            height: 70rem;
            margin: auto;
            background: #fcdbc6;
            border-radius: 50%;
            content: "";

            @include mob {
                width: 50rem;
                height: 50rem;
            }
        }

        @include desk {
            animation: 2s infinite mapMainDecor10 linear;

            @keyframes mapMainDecor10 {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 57rem 57rem;
                }
            }
        }
    }

    &__mapMainDecor._11 {
        top: 832rem;
        right: -80rem;
        z-index: 2;
        display: flex;
        width: 188rem;
        height: 188rem;
        background: repeating-linear-gradient(#fff, #fff 3.5rem, #fcdbc6 3.5rem, #fcdbc6 11.1rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);

        @include mob {
            top: 200rem;
            right: -50rem;
            width: 100rem;
            height: 100rem;
            background: repeating-linear-gradient(#fff, #fff 2rem, #fcdbc6 2rem, #fcdbc6 6.75rem);
            animation: 2s infinite mapMainDecorMob11 linear;

            @keyframes mapMainDecorMob11 {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 67rem 67rem;
                }
            }
        }

        &::before {
            width: 88rem;
            height: 88rem;
            margin: auto;
            background: #de261f;
            border-radius: 50%;
            content: "";

            @include mob {
                width: 50rem;
                height: 50rem;
            }
        }

        @include desk {
            animation: 2s infinite mapMainDecor11 linear;

            @keyframes mapMainDecor11 {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 66rem 66rem;
                }
            }
        }
    }

    &__mapMainDecor._12 {
        top: 1865rem;
        right: 116rem;
        width: 104rem;
        height: 104rem;

        @include bg("/media/light-anima-1.svg", contain, left bottom);

        animation: 7s infinite mapMainDecor12 linear;

        @include mob {
            top: 3040rem;
            right: 60rem;
            width: 80rem;
            height: 80rem;
        }

        @keyframes mapMainDecor12 {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    &__mapMainDecor._13 {
        bottom: -140rem;
        left: -150rem;
        z-index: 2;
        display: flex;
        width: 324rem;
        height: 324rem;
        background: repeating-linear-gradient(#fcdbc6, #fcdbc6 5rem, #e8251d 5rem, #e8251d 18rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);
        opacity: 0.05;

        @include mob {
            right: 94rem;
            bottom: 148rem;
            left: auto;
            width: 84rem;
            height: 84rem;
            background: repeating-linear-gradient(#fcdbc6, #fcdbc6 2rem, #e8251d 2rem, #e8251d 6rem);
            opacity: 1;
            animation: 3s infinite mapMainDecorMob13 linear;

            @keyframes mapMainDecorMob13 {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 84rem 84rem;
                }
            }
        }

        &::before {
            width: 148rem;
            height: 148rem;
            margin: auto;
            background: #fcdbc6;
            border-radius: 50%;
            content: "";

            @include mob {
                width: 38rem;
                height: 38rem;
            }
        }

        @include desk {
            animation: 2s infinite mapMainDecor13 linear;

            @keyframes mapMainDecor13 {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 72rem 72rem;
                }
            }
        }
    }

    &__mapMainDecor._14 {
        right: 100rem;
        bottom: -20rem;
        z-index: 2;
        display: flex;
        width: 102rem;
        height: 102rem;
        background: repeating-linear-gradient(#fff, #fff 2rem, #fcdbc6 2rem, #fcdbc6 6.9rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);

        @include mob {
            right: -14rem;
            bottom: 1080rem;
            width: 80rem;
            height: 80rem;
            background: repeating-linear-gradient(#fff, #fff 1.5rem, #fcdbc6 1.5rem, #fcdbc6 5.4rem);
            animation: 4s infinite mapMainDecorMob14 linear;

            @keyframes mapMainDecorMob14 {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 80rem 80rem;
                }
            }
        }

        &::before {
            width: 46rem;
            height: 46rem;
            margin: auto;
            background: #de261f;
            border-radius: 50%;
            content: "";

            @include mob {
                width: 36rem;
                height: 36rem;
            }
        }

        @include desk {
            animation: 2s infinite mapMainDecor14 linear;

            @keyframes mapMainDecor14 {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 75rem 75rem;
                }
            }
        }
    }
}
