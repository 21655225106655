.codeInputs {
    position: relative;
    width: 100%;

    &__item {
        width: 56px;
        height: 56px;
        padding: 8px;
        background: #fff;
        border-radius: 16px;

        @include mob {
            width: 51px;
        }

        & + & {
            margin-left: 12px;

            @include mob {
                margin-left: 7px;
            }
        }

        &Input {
            width: 100%;
            height: 100%;
            color: $colorDark;
            font-weight: 400;
            font-size: 18px;
            line-height: 1.4;
            text-align: center;
        }
    }

    &__loader {
        top: 50%;
        left: 100%;
        width: 26px;
        height: 26px;
        margin-left: 10px;
        transform: translate(0, -50%);
    }
}
