.checkbox {
    position: relative;
    width: 100%;
    height: 100%;

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 0;
        height: 0;
        opacity: 0;
    }

    &__view {
        position: relative;
        padding-left: 28px;
        color: $colorDark;
        font-size: 16px;
        line-height: 1.3;

        @include mob {
            font-size: 12px;
        }
    }

    &__box {
        position: absolute;
        top: 50%;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #d1d1d6;
        border-radius: 4px;
        transform: translate(0, -50%);
        transition: $trans;

        &Icon {
            width: 12px;
            height: 12px;
            opacity: 0;
            transition: $trans;
        }
    }

    &__input:checked ~ &__view &__box {
        background: $colorMain;
        border-color: $colorMain;

        &Icon {
            opacity: 1;
        }
    }
}
