.popup {
    position: relative;
    padding: 64px 0;
    overflow: hidden;
    overflow-y: auto;

    @include mob {
        padding: 40px 16px;
    }

    &__inner {
        position: relative;
        z-index: 2;
        width: 480px;
        margin: auto;

        @include mob {
            width: 100%;
        }
    }

    &:not(._withBlocks) &__inner {
        padding: 30px 60px;
        overflow: hidden;
        background: #fff;
        border-radius: 16px;
        box-shadow: 0 0 30px 0 rgba(#000, 0.15);

        @include mob {
            padding: 20px;
        }
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 2;
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 50%;

        @include mob {
            top: 10px;
            right: 10px;
            width: 24px;
            height: 24px;
        }

        &Icon {
            width: 16px;
            height: 16px;

            @include mob {
                width: 10px;
                height: 10px;
            }
        }
    }

    &__blocks {
        width: 100%;

        &Item {
            top: 50%;
            width: 100%;
            transform: translate(0, -50%);
        }
    }

    &__block {
        width: 100%;
        padding: 30px 60px;
        overflow: hidden;
        background: #fff;
        border-radius: 16px;
        box-shadow: 0 0 30px 0 rgba(#000, 0.15);

        @include mob {
            padding: 20px;
        }
    }

    &__title {
        margin-bottom: 20px;
        text-align: center;
    }

    &__description {
        text-align: center;
    }

    &__content {
        width: 100%;
        padding: 20px 0 30px;
    }

    &__scan {
        position: relative;
        z-index: 1;
        width: 340px;
        height: 340px;
        overflow: hidden;
        background: $colorBackMain;
        border-radius: 20px;

        & canvas {
            position: relative;
            z-index: 100;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__form {
        &Field {
            & + & {
                margin-top: 30px;
            }
        }

        &Upload {
            &Support {
                margin-bottom: 20px;
                text-align: center;
            }

            &Button {
                height: 56px;

                & input {
                    display: none;
                }
            }

            &Files {
                justify-content: center;

                &:not(:empty) {
                    margin-top: 20px;
                }
            }

            &File {
                position: relative;
                z-index: 1;
                width: 76px;
                height: 76px;
                overflow: hidden;
                border-radius: 10px;

                & + & {
                    margin-left: 10px;
                }

                &Preview {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &Delete {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 3;
                    width: 20px;
                    height: 20px;
                    background: $colorMain;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);

                    &Icon {
                        width: 8px;
                        height: 8px;

                        @include icon(#fff);
                    }
                }
            }
        }
    }

    &__buttons {
        &Support {
            margin-bottom: 20px;
            color: $colorDark;
            font-weight: 600;
            font-size: 18px;
            line-height: 1.3;
            text-align: center;
        }
    }

    &__button {
        height: 56px;

        & + & {
            margin-top: 20px;
        }
    }
}
