.indexHeader {
    position: relative;
    height: 800rem;
    padding: 96rem 0 84rem;

    @include mob {
        height: 520rem;
        padding: 76rem 0 70rem;
    }

    @include bg("/media/index/zagl-image.jpg", 100% auto, center top);

    background-color: #e11f26;

    @include mob {
        @include bg("/media/index-back-mob.jpg", 100% auto, center top);

        background-color: #e11f26;
    }

    &__slogan {
        width: 536rem;
        margin: 16rem 10rem 0 auto;

        @include mob {
            width: 310rem;
            margin: 0 0 0 auto;
        }
    }

    &__back {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        pointer-events: none;

        @include bg("/media/lenta-main-anima.svg", 100% auto, center bottom);

        @include mob {
            @include bg("/media/lenta-main-anima-mob.svg", 100% auto, center bottom);
        }

        &Start {
            position: absolute;
            bottom: 81rem;
            left: -8rem;
            z-index: 3;
            width: 620rem;
            height: 68rem;
            overflow: hidden;
            transform: rotate(-5.5deg);

            @include mob {
                bottom: 49rem;
                width: 66rem;
                height: 37rem;
            }

            &::before,
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 680rem;
                height: 100%;
                content: "";

                @include mob {
                    top: -1rem;
                }

                @include bg("/media/header-left-line-elems.svg", auto calc(100% - 14rem), left 7rem);
            }

            @include desk {
                @keyframes backStartLine1 {
                    0% {
                        transform: translate(0, 0);
                    }

                    100% {
                        transform: translate(-100%, 0);
                    }
                }

                @keyframes backStartLine2 {
                    0% {
                        transform: translate(680rem, 0);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                &::before {
                    animation: 7s infinite backStartLine1 linear;
                }

                &::after {
                    transform: translate(680rem, 0);
                    animation: 7s infinite backStartLine2 linear;
                }
            }

            @include mob {
                @keyframes backStartLineMob1 {
                    0% {
                        transform: translate(0, 0);
                    }

                    100% {
                        transform: translate(-286rem, 0);
                    }
                }

                @keyframes backStartLineMob2 {
                    0% {
                        transform: translate(286rem, 0);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                &::before {
                    animation: 5s infinite backStartLineMob1 linear;
                }

                &::after {
                    transform: translate(286rem, 0);
                    animation: 5s infinite backStartLineMob2 linear;
                }
            }

            &Shadow {
                position: absolute;
                top: 4rem;
                right: 0;
                z-index: 50;
                width: 40rem;
                height: calc(100% - 14rem);
                background: linear-gradient(to left, #fff, rgba(#fff, 0));
                will-change: transform;

                @include mob {
                    width: 20rem;
                }
            }
        }

        &End {
            position: absolute;
            right: -7rem;
            bottom: 163rem;
            z-index: 2;
            width: 757rem;
            height: 63.5rem;
            overflow: hidden;

            // background: rgba(blue, 0.5);
            transform: rotate(-5.5deg);

            @include mob {
                bottom: 71rem;
                width: 278rem;
                height: 42rem;
            }

            &Inner {
                position: relative;
                z-index: 2;
                width: 330rem;
                height: 100%;
                margin-left: auto;
                overflow: hidden;

                // background: rgba(blue, 0.5);

                @include mob {
                    width: 60rem;
                }

                &::before,
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 620rem;
                    height: 100%;
                    content: "";

                    @include bg("/media/header-left-line-elems.svg", auto calc(100% - 14rem), left 7rem);
                }

                @include desk {
                    &::before {
                        animation: 6.8s infinite backEndLine1 linear;
                    }

                    &::after {
                        transform: translate(620rem, 0);
                        animation: 6.8s infinite backEndLine2 linear;
                    }

                    @keyframes backEndLine1 {
                        0% {
                            transform: translate(0, 0);
                        }

                        100% {
                            transform: translate(-100%, 0);
                        }
                    }

                    @keyframes backEndLine2 {
                        0% {
                            transform: translate(620rem, 0);
                        }

                        100% {
                            transform: translate(0, 0);
                        }
                    }
                }

                @include mob {
                    &::before {
                        animation: 5s infinite backEndLineMob1 linear;
                    }

                    &::after {
                        transform: translate(320rem, 0);
                        animation: 5s infinite backEndLineMob2 linear;
                    }

                    @keyframes backEndLineMob1 {
                        0% {
                            transform: translate(0, 0);
                        }

                        100% {
                            transform: translate(-320rem, 0);
                        }
                    }

                    @keyframes backEndLineMob2 {
                        0% {
                            transform: translate(320rem, 0);
                        }

                        100% {
                            transform: translate(0, 0);
                        }
                    }
                }
            }

            &Shadow {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 4;
                width: 40rem;
                height: 100%;
                background: linear-gradient(to right, #fff, rgba(#fff, 0));
            }
        }
    }
}
