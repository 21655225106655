.topBar {
    position: relative;
    transition: $trans;

    &._showMenu {
        background: #e11f26;
    }

    &__head {
        position: relative;
        height: 88rem;
        padding: 16rem 0;
        transition: $trans;
        pointer-events: visible;

        @include mob {
            height: 64rem;
            padding: 6rem 0;
        }

        &Inner {
            flex-direction: row;
            align-items: center;

            @include mob {
                height: 100%;
            }
        }
    }

    &._fix &__head {
        background: #e11f26;
    }

    &__menu {
        width: 100%;
        height: calc(100% - 88rem);

        @include mob {
            height: calc(100% - 64rem);
        }

        &[data-show] {
            pointer-events: visible;
        }
    }

    &__stateButton {
        position: relative;
        width: 50rem;
        height: 50rem;
        margin-right: 30rem;

        @include button;

        @include mob {
            width: 40rem;
            height: 40rem;
        }

        &Item {
            position: absolute;
            top: 0;
            left: 0;
            transition: $trans;

            &::before {
                width: 100%;
                height: 4rem;
                background: #de261f;
                transition: $trans;
                content: "";

                @include mob {
                    height: 3rem;
                }
            }

            &:first-child {
                transform: translate(0, -10rem);

                @include mob {
                    transform: translate(0, -8rem);
                }
            }

            &:last-child {
                transform: translate(0, 10rem);

                @include mob {
                    transform: translate(0, 8rem);
                }
            }
        }
    }

    &._fix &__stateButton,
    &._showMenu &__stateButton {
        &Item {
            &::before {
                background: #fff;
            }
        }
    }

    &._showMenu &__stateButton {
        &Item {
            &:first-child {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }

    &__logo {
        width: 144rem;
        height: 46rem;

        @include icon(#de261f);

        @include mob {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            width: 124rem;
            height: 40rem;
            transform: translate(-50%, -50%);
        }
    }

    &._showMenu &__logo,
    &._fix &__logo {
        @include icon(#fff);
    }

    &__buttons {
        margin-left: auto;
        transition: $trans;
    }

    &._showMenu &__buttons {
        opacity: 0;
        pointer-events: none;
    }

    &._fix &__button {
        &._red {
            background: #ff3427;
        }
    }

    &__button {
        position: relative;
        z-index: 1;
        height: 56rem;
        padding: 11rem 17rem 10rem;
        overflow: hidden;
        text-transform: uppercase;
        border-radius: 12rem;

        @include button;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: rgba(#fff, 0.1);
            opacity: 0;
            transition: $trans;
            content: "";
        }

        &:hover::after {
            opacity: 1;
        }

        &._purple {
            background: #5d279e;
        }

        &._pink {
            background: #ff49b8;
        }

        &._red {
            background: #e11f26;
        }

        & + & {
            margin-left: 8rem;
        }

        &Logo {
            width: 32rem;
            height: 32rem;
            margin-right: 10rem;
            object-fit: contain;
        }

        &Content {
            color: #fff;
            font-weight: 600;
            font-size: 14rem;
            transition: $trans;

            &._hide {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                white-space: nowrap;
                transform: translate(0, 100%);
            }
        }

        @keyframes content {
            0% {
                transform: translate(0, 0);
            }

            100% {
                transform: translate(0, -56rem);
            }
        }

        @keyframes hideContent {
            0% {
                transform: translate(0, 100%);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        &._logo &Content._hide {
            padding-left: 42rem;
        }

        &:hover &Content:not(._hide) {
            animation: 0.3s 1 content ease-in-out;
        }

        &:hover &Content._hide {
            animation: 0.3s 1 hideContent ease-in-out;
        }
    }

    &__user {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28rem;
        height: 28rem;
        margin: -6rem 6rem 0 auto;
        transition: $trans;

        &::before {
            position: absolute;
            bottom: -7rem;
            left: 50%;
            z-index: -1;
            width: calc(100% + 14rem);
            height: 100rem;
            background: #f72d25;
            border-radius: 0 0 16rem 16rem;
            transform: translate(-50%, 0);
            transition: $trans;
            content: "";
        }

        &::after {
            @include bg("/media/user.svg", contain, center);

            position: relative;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
            content: "";
        }
    }

    &._fix &__user,
    &._showMenu &__user {
        margin-top: 0;

        &::before {
            opacity: 0;
        }
    }
}
