.errors {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100001;
    width: 100%;
    padding: 0 24px 28px;
    pointer-events: none;

    @include mob {
        padding: 0 16px 16px;
    }

    &__list {
        width: 100%;

        &Item {
            top: auto;
            bottom: 0;
            width: 100%;
        }

        &._static &Item:not(._last) {
            margin-bottom: 8px;
        }
    }

    &__item {
        width: 100%;
        padding: 16px 16px 16px 50px;
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.2;
        background: $colorError;
        border-radius: 10px;
        box-shadow: 12px 12px 12px 0 rgba(#000, 0.1);

        @include mob {
            padding-left: 46px;
            font-size: 14px;
        }

        &Icon {
            position: absolute;
            top: 50%;
            left: 20px;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            padding: 4px;
            background: #fff;
            border-radius: 50%;
            transform: translate(0, -50%);

            @include mob {
                width: 16px;
                height: 16px;
            }

            @include icon($colorError);
        }
    }
}
